/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
export const regexPassword = new RegExp('[!"#$%&()*+,\-./:;<=>?@[\\\]^_`{|}~]')
export const regexLenght = new RegExp('^.{8,}$')
export const regexNumber = new RegExp('[0-9]')
export const regexUppercase = new RegExp('[A-Z]')
export const regexLowercase = new RegExp('[a-z]')
export const regexEmail = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
export const regexTwoDecimalPlaces = new RegExp('/.d{2}$/g')
export const regexVerificationCode = new RegExp(/\b\d{5}\b/g)
export const regexLegalName = new RegExp(/^[a-z ,.'-]+$/i)
export const regexBreakLines = new RegExp(/\r\n|\r|\n/)

